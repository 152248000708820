/* tslint:disable */
/* eslint-disable */
/**
 * AllOfUs Workbench API
 * The API for the AllOfUs workbench.  Throughout, we use integer/int64 in preference to string/date-time because Swagger\'s date formatting is inconsistent between server and client. Time values are stored as milliseconds since the UNIX epoch.  Note: all requests tagged as \"cron\" must have the header X-Appengine-Cron: true, which app engine itself only sets when invoking as a cronjob. See https://cloud.google.com/appengine/docs/standard/java/config/cron#securing_urls_for_cron and o.p.w.interceptors.CronInterceptor which implements the header check. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatusAlertLocation } from './StatusAlertLocation';
import {
    StatusAlertLocationFromJSON,
    StatusAlertLocationFromJSONTyped,
    StatusAlertLocationToJSON,
} from './StatusAlertLocation';

/**
 * 
 * @export
 * @interface StatusAlert
 */
export interface StatusAlert {
    /**
     * The primary key of the status alert in the database
     * @type {number}
     * @memberof StatusAlert
     */
    statusAlertId?: number;
    /**
     * Title of the status alert, e.g. 'Service Incident: December 6, 2019'
     * @type {string}
     * @memberof StatusAlert
     */
    title: string;
    /**
     * The actual message of the status alert, e.g. 'AoU RW is down because GCP is down'
     * @type {string}
     * @memberof StatusAlert
     */
    message: string;
    /**
     * A URL linking to an incident report where users can read more
     * @type {string}
     * @memberof StatusAlert
     */
    link?: string;
    /**
     * 
     * @type {StatusAlertLocation}
     * @memberof StatusAlert
     */
    alertLocation: StatusAlertLocation;
    /**
     * Start time for when this alert should be shown, in milliseconds since epoch. Null starts immediately.
     * @type {number}
     * @memberof StatusAlert
     */
    startTimeEpochMillis: number;
    /**
     * End time for when this alert should no longer be shown, in milliseconds since epoch. Null means it never expires.
     * @type {number}
     * @memberof StatusAlert
     */
    endTimeEpochMillis?: number;
}

/**
 * Check if a given object implements the StatusAlert interface.
 */
export function instanceOfStatusAlert(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "alertLocation" in value;
    isInstance = isInstance && "startTimeEpochMillis" in value;

    return isInstance;
}

export function StatusAlertFromJSON(json: any): StatusAlert {
    return StatusAlertFromJSONTyped(json, false);
}

export function StatusAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusAlert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusAlertId': !exists(json, 'statusAlertId') ? undefined : json['statusAlertId'],
        'title': json['title'],
        'message': json['message'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'alertLocation': StatusAlertLocationFromJSON(json['alertLocation']),
        'startTimeEpochMillis': json['startTimeEpochMillis'],
        'endTimeEpochMillis': !exists(json, 'endTimeEpochMillis') ? undefined : json['endTimeEpochMillis'],
    };
}

export function StatusAlertToJSON(value?: StatusAlert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusAlertId': value.statusAlertId,
        'title': value.title,
        'message': value.message,
        'link': value.link,
        'alertLocation': StatusAlertLocationToJSON(value.alertLocation),
        'startTimeEpochMillis': value.startTimeEpochMillis,
        'endTimeEpochMillis': value.endTimeEpochMillis,
    };
}

